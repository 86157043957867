import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import Keycloak from "keycloak-js";
import { resolvePath } from "./utils";

(window as any).process = {
  env: {
    NODE_ENV: "production",
  },
};

const keycloakInstance = Keycloak("/keycloak.json");

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
  registerApplication({
    ...app,
    customProps: (name, location) => {
      const extraProps = {
        basename: "/",
        appPath: resolvePath(location.pathname, "", routes as any),
        keycloak: keycloakInstance,
        isRootPath: false,
      };

      if (app.customProps) {
        if (typeof app.customProps === "function") {
          return { ...app.customProps(name, location), ...extraProps };
        } else {
          return { ...app.customProps, ...extraProps };
        }
      }

      return { name, ...extraProps };
    },
  });
});
layoutEngine.activate();

start();
