type RouteItem = {
  routes?: RouteItem[];
  path?: string;
  base?: string;
  type?: "route" | "application";
  connectedNode?: any;
  name?: string;
  default?: boolean;
  redirects?: any;
} & Record<string, any>;

function joinPath(currentPath: string, routeItem: RouteItem) {
  if (routeItem.path) {
    return `${currentPath}/${routeItem.path}`;
  }

  return currentPath;
}

function isValid(
  targetPath: string,
  currentPath: string,
  routeItem: RouteItem
): boolean {
  const joinedPath = joinPath(currentPath, routeItem);
  const isValid = targetPath.startsWith(joinedPath);

  return isValid;
}

export function resolvePath(
  targetPath: string,
  currentPath: string,
  routeItem: RouteItem
) {
  const joinedCp = joinPath(currentPath, routeItem);

  if (routeItem.routes) {
    const validItems = routeItem.routes
      .filter((ri) => ri.type === "route" || ri.routes)
      .filter((ri) => isValid(targetPath, joinedCp, ri))
      .sort((a, b) => {
        const apath = joinPath(currentPath, a);
        const bpath = joinPath(currentPath, b);

        if (apath.length === bpath.length) {
          const aroutes = a?.routes?.length || 0;
          const broutes = b?.routes?.length || 0;

          if (aroutes === broutes) {
            return 0;
          }

          return aroutes > broutes ? -1 : 1;
        }

        return apath.length > bpath.length ? -1 : 1;
      });

    if (validItems.length > 0) {
      return resolvePath(targetPath, joinedCp, validItems[0]);
    }
  }

  return joinedCp;
}
